<template>
  <div class="abtestA" id="basicPage">
    <div class="basic-info">
      <form action="" class="basic-form" id="basicForm">
        <div class="info-item">
          <div class="item-title1">
            <span class="title1">剩余条数：</span>
          </div>
          <div class="sex" style="color:red;font-size:20px">
            {{platnum }}
          </div>
        </div>
        <div class="info-item">
          <div class="item-title1">
            <span class="title1" style="width: 80px"
              >联&nbsp;&nbsp;系&nbsp;&nbsp;人：</span
            >
          </div>
          <input type="text" class="input width100" v-model="fbxx.user" />
        </div>
        <div class="info-item">
          <div class="item-title1">
            <span class="title1" style="width: 80px"
              >联&nbsp;系&nbsp;方&nbsp;式：</span
            >
          </div>
          <input type="text" class="input width100" v-model="fbxx.phone" />
        </div>
        <div class="info-item">
          <div class="item-title1">
            <span class="title1" style="width: 80px"
              >职&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;位：</span
            >
          </div>
          <input type="text" class="input width100" v-model="fbxx.title" />
        </div>
        <div class="info-item">
          <div class="item-title1">
            <span class="title1" style="width: 80px"
              >月&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;薪：</span
            >
          </div>
          <input
            type="text"
            class="input width1001"
            placeholder="最低薪资"
            v-model="fbxx.salaryS"
          />-<input
            type="text"
            class="input width1001"
            placeholder="最高薪资"
            v-model="fbxx.salaryE"
          />
        </div>
        <div class="info-item">
          <div class="item-title1">
            <span class="title1">职位简介：</span>
          </div>
          <input type="text" class="input width100" v-model="fbxx.content" />
        </div>
        <div class="info-item">
          <div class="item-title1">
            <span class="title1">公司名称：</span>
          </div>
          <input
            type="text"
            class="input width100"
            v-model="fbxx.companyName"
          />
        </div>
        <div class="info-item">
          <div class="item-title1">
            <span class="title1">公司简介：</span>
          </div>
          <input type="text" class="input width100" v-model="fbxx.company" />
        </div>
        <div class="info-item">
          <div class="item-title1">
            <span class="title1">消息分类：</span>
          </div>
          <select v-model="fbxx.type" class="input width100">
            <option value="">请选择校区</option>
            <option value="保洁">保洁</option>
            <option value="服务员">服务员</option>
            <option value="人事专员">人事专员/助理</option>
            <option value="销售">销售</option>
            <option value="司机">司机</option>
            <option value="客服">客服</option>
            <option value="餐饮">餐饮</option>
            <option value="酒店">酒店</option>
            <option value="美发">美容/美发</option>
            <option value="运动健身">运动健身</option>
          </select>
        </div>
        <div class="toudi" @click.prevent="toudi()">投递</div>
        <div class="quxiao" @click.prevent="$emit('fabu', !out.xiaoxifabu1)">
          取消
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      sex: "",
      token: "",
      fbxx: {
        user: "",
        phone: "",
        title: "",
        salaryS: "",
        salaryE: "",
        content: "",
        company: "", //公司简介
        type: "",
        companyName: "", //公司名称
      },
      platnum: "",
    };
  },
  props: ["out"],
  mounted() {
    this.platnum = Cookies.get("platnum");
    console.log("阿萨德哈数111", this.platnum );
  },
  methods: {


    getFukuanma() {
      console.log("kjagdas", this.sex);
      if (this.sex == "一百条付费发布") {
        this.fukuanma = true;
      }
    },

    //发布消息
    toudi() {
      if (this.fbxx.user == "") {
        alert("请输入姓名");
      } else if (this.fbxx.phone == "") {
        alert("请输入手机号");
      } else if (this.fbxx.title == "") {
        alert("请输入职位");
      } else if (this.fbxx.salaryS == "") {
        alert("请输入最低薪资");
      } else if (this.fbxx.salaryE == "") {
        alert("请输入最高薪资");
      } else if (this.fbxx.content == "") {
        alert("请输入职位简介");
      } else if (this.fbxx.company == "") {
        alert("请输入公司简介");
      } else if (this.fbxx.type == "") {
        alert("请选择职位分类");
      } else if (this.fbxx.companyName == "") {
        alert("请输入公司名称");
      } else {
        axios.post(
          // "http://192.168.28.37:15001/message",
          "http://123.56.43.64:15001/message",
          {
            user: this.fbxx.user,
            phone: this.fbxx.phone,
            title: this.fbxx.title,
            salaryS: this.fbxx.salaryS,
            salaryE: this.fbxx.salaryE,
            content: this.fbxx.content,
            company: this.fbxx.company,
            type: this.fbxx.type,
            companyName: this.fbxx.companyName,
            status: 1,
          },
          {
            headers: {
              token: this.out.token,
            },
          }
        );
        this.$emit("fabu", !this.out.xiaoxifabu1);
      }
    },
  },
};
</script>

<style>
div {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
#basicPage {
  width: 100%;
  height: 100%;
}

#basicPage.abtestA .basic-info {
  border: 1px solid #ddd;
  background-color: rgb(255, 253, 253);
  margin: 200px 0 0 650px;
  position: absolute;
  padding: 30px 0;
  max-height: 531px;
  overflow-y: auto;
  z-index: 999;
}
#basicPage .basic-form {
  width: 585px;
  display: inline-block;
}
#basicPage.abtestA .info-item {
  margin-bottom: 14px;
}
#basicPage .item-title1 {
  /* width: 100px; */
  float: left;
  line-height: 40px;
}
.item-title1 {
  display: inline-block;
  margin-right: 12px;
  font-weight: 700;
}
#basicPage.abtestA .input {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  margin-left: 30px;
}
.input {
  font-size: 14px;
  color: #333;
  border: 1px solid #ddd;
}
.title1 {
  display: inline-block;
  color: #666;
  margin-left: 35px;
  font-size: 16px;
}
#basicPage .width100 {
  width: 340px;
}
.width1001 {
  width: 70px;
}
.sex {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  margin-left: 20px;
}
.submit {
  position: fixed;
  width: 100px;
  height: 40px;
  top: 90px;
  left: 600px;
}
.quxiao {
  width: 100px;
  height: 40px;
  cursor: pointer;
  background-color: #ccc;
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 40px;
  display: inline-block;
  position: relative;
  top: 20px;
  left: 50px;
}
.toudi {
  display: inline-block;
  width: 100px;
  height: 40px;
  background-color: #ccc;
  color: rgb(255, 255, 255);
  cursor: pointer;
  background-color: #ff552e;
  text-align: center;
  line-height: 40px;
  position: relative;
  top: 20px;
  left: 300px;
}
.submit1 {
  position: fixed;
  width: 100px;
  height: 40px;
  top: 90px;
  left: 600px;
}

#basicPage .submit-btn {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 100px;
}
.fukuanma {
  margin-left: 230px;
}
</style>
