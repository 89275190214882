import { createRouter, createWebHashHistory } from 'vue-router'
import Shangpin from '../views/fuwuyuan.vue'
const routes = [
  {
    path: '/',
    name: 'Shangpin',
    component: Shangpin
  },

  {
    path: '/baojie1',
    name: 'baojie1',
    component: () => import('../views/baojie1.vue')
  },
  {
    path: '/Denglu',
    name: 'Denglu',
    component: () => import('../views/Denglu.vue')
  },
  {
    path: '/zhuce',
    name: 'zhuce',
    component: () => import('../views/zhuce.vue')
  },
  {
    path: '/shangpin',
    name: 'shangpin',
    component: () => import('../views/Shangpin.vue')
  },
  {
    path: '/renshi',
    name: 'renshi',
    component: () => import('../views/renshi.vue')
  },
  
  {
    path: '/xiaoshou',
    name: 'xiaoshou',
    component: () => import('../views/xiaoshou.vue')
  },
  {
    path: '/siji',
    name: 'siji',
    component: () => import('../views/siji.vue')
  },
  {
    path: '/kefu',
    name: 'kefu',
    component: () => import('../views/kefu.vue')
  },
  {
    path: '/canyin',
    name: 'canyin',
    component: () => import('../views/canyin.vue')
  },
  {
    path: '/jiudian',
    name: 'jiudian',
    component: () => import('../views/jiudian.vue')
  },
  {
    path: '/meifa',
    name: 'meifa',
    component: () => import('../views/meifa.vue')
  },
  {
    path: '/jianshen',
    name: 'jianshen',
    component: () => import('../views/jianshen.vue')
  },
  {
    path: '/gerenzhongxin',
    name: 'gerenzhongxin',
    component: () => import('../views/gerenzhongxin.vue')
  },
  {
    path: '/yhxy',
    name: 'yhxy',
    component: () => import('../views/yhxy.vue')
  },
  {
    path: '/yszc',
    name: 'yszc',
    component: () => import('../views/yszc.vue')
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router